// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("jquery")
// require("bootstrap")
// require("popper.js")
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
require("libs/custom_nested_form")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const handleResponse = (response) => {
  if (response.status === 500) {
    alert('Backend has reported a cache problem, the data may be invalid, consider reloading page and wait for a while. Apologies for inconvenience.');
  }

  return response.text().then((text) => {
    const incorrectStatus = response.status !== 200;
    let data = null;
    try {
      data = JSON.parse(text);
    } catch (e) {
      data = null;
    }
    // const data = (text && !incorrectStatus) ?  : null;
    const hasErrors = (data && (data.errors || data.error));
    const error = hasErrors ? (data.errors ? data.errors : data.error) : {};

    // console.log({ hasErrors, text, incorrectStatus, error })

    return (hasErrors || incorrectStatus) ? Promise.reject(error) : Promise.resolve(data);
  });
};

function updateNotifications() {
  const location_id = $('body').data('location-id');
  fetch(`/member/${location_id}/notifications/count`, { method: 'GET', headers: {'Accept': 'application/json'} }).then(handleResponse).then(function(data) {
    $('#notifications-count').html(data.count);
    if(data.count > 0) {
      $('#notifications-container').removeClass('hidden');
      if(window.notifications_count !== undefined && window.notifications_count < data.count) {
        window.newCustomerAudio.play();
        if($('#customers-table').length > 0) {
          $.ajax({
            url: window.location.href,
            dataType: 'script'
          })
        }
      }
    } else {
      $('#notifications-container').addClass('hidden');
    }
    window.notifications_count = data.count;
  });
}

window.addLocationFormEvents = function() {
  $('#user_details_form').find("[type=submit]").click(function(e){
    e.preventDefault();
    var adr = ['data-address_line_1', 'data-address_line_2', 'data-city', 'data-state', 'data-zip'].map(function(el){ return $('[data-field="'+el+'"]').val() });
    adr.push('USA');
    var address = adr.filter(function(t){ return !!t }).join(', ');
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, function(results, status) {
      if(status === 'OK') {
        var lat = results[0].geometry.location.lat(),
            lng = results[0].geometry.location.lng();
        $('[data-field=lat]').val(lat);
        $('[data-field=lng]').val(lng);
        $('#user_details_form').submit();
      } else {
        alert('Address was not found in Google maps');
      }
    })
  });
}

window.addBillingFormEvents = function() {
  $('#stripe-coupon').on('keyup', function(){
    $.ajax({
      type: 'GET',
      dataType: "json",
      accepts: {
          text: "application/json"
      },
      url: "/api/coupons/" + $('#stripe-coupon').val() + "?plan_id="+$('input[name="user[plan_id]"]:checked').val(),
      success: function(data) {
        $('#coupon-message').removeClass('error').html('Your invoice will be reduced by $'+data.discount);
      },
      error:  function(xhr, str){
        $('#coupon-message').addClass('error').html('Invalid coupon');
      }
    });
  });
}

$(function(){
  window.notifications_count = undefined;
  window.newCustomerAudio = new Audio('/bell.mp3');

  const location_id = $('body').data('location-id');
  if(location_id) {
    setInterval(updateNotifications, 5000);
  }
})
